import { FormattedMessage } from 'react-intl'
import ErrorPage from '@/components/ErrorPage'

export function getStaticProps() {
  return {
    props: {},
  }
}

export default function Custom404() {
  const illustrationContainerCSS = `
    min-height: 65vh;
    
    > div:first-child {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }
  `

  return (
    <ErrorPage
      title={ (
        <FormattedMessage
          defaultMessage="Page not found"
          id="HfEl6c"
          description="404 page title"
        />
      ) }
      subtitle={ (
        <FormattedMessage
          defaultMessage="We couldn't find the page you were looking for."
          id="WrCo4m"
          description="404 page subtitle"
        />
      ) }
      illustrationUrl="/images/404.svg"
      containerCSS={ illustrationContainerCSS }
      buttonLabel={ (
        <FormattedMessage
          defaultMessage="Go to the homepage"
          id="4tUhkX"
          description="404 page button label"
        />
      ) }
      buttonLink="/"
      buttonColor="green"
      buttonIcon="home"
    />
  )
}
