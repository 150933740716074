export function PhoneIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.6206 52.4064L21.8289 43.9135L21.8351 43.9324C17.9028 38.1721 15.5786 31.4695 15.1012 24.5094L14.1356 10.5L13.0019 11.6337C10.8064 13.8292 9.15068 16.4978 8.16815 19.4453L7.50708 21.4104C5.83924 26.3957 6.19707 31.8299 8.49567 36.5545L12.2802 44.3115L20.0372 56.417C25.3137 64.5622 32.2519 71.5005 40.3971 76.7769L52.5026 84.5339L60.2597 88.3185C64.9842 90.6231 70.4244 90.9749 75.4037 89.307L77.3688 88.646C80.3102 87.6634 82.9849 86.0077 85.1804 83.8122L87.8853 81.1073C89.5835 79.4091 90.5296 77.0923 90.4993 74.6845C90.4591 71.5946 88.8331 68.8001 86.2832 67.2149C86.1271 67.1493 85.9686 67.0876 85.8078 67.0301C85.5063 66.9233 85.1733 66.9987 84.9472 67.2249L77.0386 75.1335C72.7796 79.3925 66.3032 80.5169 60.8507 77.9477L53.4446 74.4488C43.0232 69.5302 34.1095 61.9231 27.6206 52.4064Z" fill="#009877" />
      <path d="M48.5 20.3433C55.6674 20.3433 62.8348 23.0763 68.3009 28.5424C73.767 34.0084 76.5 41.1758 76.5 48.3433" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M88.5 48.3433C88.5 38.1065 84.5971 27.8697 76.7853 20.0579C68.9735 12.2461 58.7368 8.34326 48.5 8.34326" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M76.5 48.3433C76.5 41.1758 73.767 34.0084 68.3009 28.5424C62.8348 23.0763 55.6674 20.3433 48.5 20.3433" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M48.5 32.3433C52.5965 32.3433 56.6931 33.9059 59.8122 37.031C62.9314 40.1562 64.5 44.2528 64.5 48.3433" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M64.5 48.3433C64.5 44.2467 62.9374 40.1502 59.8122 37.031C56.687 33.9119 52.5905 32.3433 48.5 32.3433" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.0614 56.4279C25.336 64.5701 32.2719 71.5058 40.4143 76.7804L52.5157 84.5345L60.27 88.3177C64.993 90.6215 70.4313 90.9731 75.4089 89.3059L77.3733 88.645C80.3137 87.6629 82.9875 86.0078 85.1822 83.8131L87.8862 81.1091C89.5838 79.4116 90.5296 77.0956 90.4993 74.6887C90.4508 70.9602 88.0924 67.6621 84.588 66.401L70.4253 61.3326C68.0365 60.4778 65.3689 61.1568 63.6773 63.0423L60.9369 66.104C59.1363 68.1168 56.117 68.4745 53.8919 66.9345L41.5541 58.4104C40.9417 57.986 40.3718 57.5131 39.8504 56.9917C39.329 56.4703 38.8501 55.8944 38.4317 55.2881L29.9074 42.9505C28.3735 40.7255 28.7251 37.7063 30.738 35.9057L33.7997 33.1653C35.6913 31.4738 36.3643 28.8063 35.5095 26.4176L30.4409 12.2551C29.1859 8.7448 25.8817 6.38641 22.153 6.34397C19.7461 6.31366 17.4362 7.25338 15.7325 8.957L13.0285 11.661C10.8337 13.8557 9.17859 16.5232 8.19641 19.4697L7.53557 21.434C5.86829 26.4176 6.226 31.8497 8.52381 36.5726L12.307 44.3268L20.0614 56.4279V56.4279Z" stroke="#06262D" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
