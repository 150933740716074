export default function EmailIcon() {
  return (
    <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.377 20.3643C15.8135 20.3457 15.3416 20.7875 15.323 21.351L15.2436 23.7515L82.1008 29.9661C82.6622 30.0183 83.075 30.5158 83.0229 31.0772L79.1072 73.2018L84.8544 73.3917C85.4179 73.4103 85.8898 72.9686 85.9084 72.405L87.5165 23.7363C87.5351 23.1728 87.0934 22.7008 86.5298 22.6822L16.377 20.3643Z" fill="#009877" />
      <rect x="11.1953" y="23.3751" width="72.233" height="50.7372" rx="1.40625" transform="rotate(5.31064 11.1953 23.3751)" stroke="#06262D" strokeWidth="1.25" />
      <path d="M11.9541 24.2555C11.6787 23.9359 11.9325 23.4437 12.3526 23.4827L81.961 29.9531C82.381 29.9921 82.5397 30.5228 82.2101 30.7861L46.0534 59.6693C44.8718 60.6133 43.1551 60.4537 42.1677 59.3081L11.9541 24.2555Z" stroke="#06262D" strokeWidth="1.25" />
      <path d="M6.80475 73.4475L34.4768 50.4823" stroke="#06262D" strokeWidth="1.25" />
      <path d="M78.121 80.0769L55.1559 52.4047" stroke="#06262D" strokeWidth="1.25" />
      <path d="M6.80475 73.4475L34.4768 50.4823" stroke="#06262D" strokeWidth="1.25" />
      <path d="M78.121 80.0769L55.1559 52.4047" stroke="#06262D" strokeWidth="1.25" />
    </svg>
  )
}
