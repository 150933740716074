import React from 'react'
import AppChrome from './AppChrome'
import { ButtonColors } from './Button'
import ContactErrorFooter from './contact/ContactErrorFooter'
import HeroIllustration from './heroes/HeroIllustration'
import Stack from './layout/Stack'

type ErrorPageProps = {
  title: React.ReactNode
  subtitle: React.ReactNode
  illustrationUrl: string
  containerCSS: string
  buttonLabel: React.ReactNode
  buttonLink: string
  buttonColor: ButtonColors
  buttonIcon: string
};

export default function ErrorPage( {
  title,
  subtitle,
  illustrationUrl,
  containerCSS,
  buttonLabel,
  buttonLink,
  buttonColor,
  buttonIcon,
}: ErrorPageProps ) {
  return (
    <AppChrome>
      <Stack $between={ 8 }>
        <HeroIllustration 
          title={ title }
          subtitle={ subtitle }
          image={ {
            url: illustrationUrl,
            alt: title,
          } }
          imagePosition="center"
          bgColor="realWhite"
          objectPosition="center"
          objectFit="contain" 
          containerCSS={ containerCSS }
          buttonLabel={ buttonLabel }
          buttonLink={ buttonLink }
          buttonColor={ buttonColor }
          buttonIcon={ buttonIcon }
        />
        <ContactErrorFooter />
      </Stack>
    </AppChrome>
  )
}
