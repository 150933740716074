import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Container from '../layout/Container'
import { StyledContactCard } from './ContactCard'
import Link from '../Link'
import { PhoneIcon } from '../promotionOnboarding/icons/PhoneIcon'
import EmailIcon from '../promotionOnboarding/icons/EmailIcon'

export default function ContactErrorFooter() {
  return (
    <StyledContactErrorFooterBorder>
      <Container>
        <StyledContactErrorFooter>
          <div>
            <h2>
              <FormattedMessage
                defaultMessage="Get in touch"
                id="BTZrof"
                description="Contact title: Get in touch"
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="If you need help selling or buying, or would like to report a problem, please contact Brixel."
                id="gus/j8"
                description="Error contact subtitle"
              />
            </p>
          </div>
          <StyledContactCards>
            <ContactCard
              title={ (
                <FormattedMessage
                  defaultMessage="Call us"
                  id="mWoieu"
                  description="Contact card title: Call us"
                />
            ) }
              linkText="+41 44 552 57 30"
              linkHref="tel:+41445525730"
              icon={ <PhoneIcon /> }
            />
            <ContactCard
              title={ (
                <FormattedMessage
                  defaultMessage="Email us"
                  id="QhhPzk"
                  description="Contact card title: Email us"
                />
            ) }
              linkText="hallo@brixel.ch"
              linkHref="mailto:hallo@brixel.ch"
              icon={ <EmailIcon /> }
            />
          </StyledContactCards>
        </StyledContactErrorFooter>
      </Container>
    </StyledContactErrorFooterBorder>
  )
}

function ContactCard( {
  title,
  linkText,
  linkHref,
  icon,
} ) {
  return (
    <StyledContactCard>
      { icon }
      <p style={ { wordBreak: 'normal', whiteSpace: 'nowrap' } }>
        { title }
        <Link href={ linkHref }>
          { linkText }
        </Link>
      </p>
    </StyledContactCard>
  )
}

const StyledContactErrorFooterBorder = styled.div`
  border-top: 1px solid ${ p => p.theme.colors.grey200 };
`

const StyledContactErrorFooter = styled.div`
  padding: calc( 8 * var( --stack-basis ) ) 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > * {
    &:first-child {
      flex: 0 0 50%;
      max-width: 25rem;
      margin-right: auto;

      h2 {
        margin: 0 0 1rem 0;
        font-size: ${ p => p.theme.typo.sizes.large };
      }
    }
  }

  @media (min-width: 1050px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

const StyledContactCards = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc( 2 * var( --grid-gutter ) );

  > * {
    max-width: 305px;
    /* min-width: min( 250px, 100% ); */
    flex: 0 0 25%;
  }

  @media (min-width: 500px) {
    flex-direction: row;
  }
  
  @media (min-width: 1050px) {
    justify-content: space-evenly;
    align-items: flex-start;
  }
`
